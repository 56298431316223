<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 m-auto pt-5">
          <div class="card mt-5">
            <div class="card-header bg-white">
              <h3 class="text-center mb-0 text-secondary">DC项目管理系统</h3>
            </div>
            <div class="card-body">
              <el-form ref="ruleForm" :rules="rules" :model="form">
                <el-form-item prop="username">
                  <el-input
                    v-model="form.username"
                    size="medium"
                    placeholder="请输入用户名"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    v-model="form.password"
                    size="medium"
                    type="password"
                    placeholder="请输入密码"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="medium"
                    class="w-100"
                    @click="submit"
                    :loading="loading"
                    >{{ loading ? "登录中..." : "立即登录" }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="footer">
    <el-link :href="homeinfo.url" target="_blank">{{ homeinfo.url }}</el-link>
    <el-link href="https://beian.miit.gov.cn" target="_blank">{{
      homeinfo.keep_record
    }}</el-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
      homeinfo: {
        url: "", //域名地址
        keep_record: "", // 网站备案号
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    axios
      .get("admin/gethomeinfo", {
        params: {},
      })
      .then((response) => {
        console.log("******", response.data.data, "******");
        this.homeinfo.url = response.data.data[0]["url"];
        this.homeinfo.keep_record = response.data.data[0]["keep_record"];
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        // 总是会执行
      });
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((e) => {
        if (!e) return;
        //提交表单
        this.loading = true;
        this.axios
          .post("/admin/login", this.form)

          .then((res) => {
            //存储到VUEX
            //存储到本地存储
            console.log(res.data.data);
            this.$store.commit("login", res.data.data);
            //成功提示
            this.$message("登录成功！");
            this.loading = false;
            //跳转后台首页
            this.$router.push({ name: "project_ProjectList" });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            }
          });
      });
    },
  },
};
</script>

<style>
#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #333;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
